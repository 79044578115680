import React from "react";

import clsx from "clsx";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { Box } from "~components/Box";

import * as styles from "./styles.css";

import type { BoxProps } from "~components/Box";

export interface AnimLayerCalendarProps extends BoxProps {
  title: string;
  description: string;
}

const DAYS = Array.from({ length: 31 });

export function AnimLayerCalendar({
  title,
  description,
  ...rest
}: AnimLayerCalendarProps) {
  return (
    <Box {...rest} display="flex" flexDirection="column" gap="spacing1">
      {title && (
        <Box
          color="text_highContrast"
          textAppearance="body_md"
          fontWeight="semibold"
        >
          {title}
        </Box>
      )}
      {description && (
        <Box textAppearance="body_sm" color="text_lowContrast">
          {description}
        </Box>
      )}

      <Box
        className={variantBackgroundColor.background_sand}
        display="grid"
        gap="spacing1"
        borderRadius="sm"
        padding="spacing2"
        __gridTemplateColumns="repeat(7, 1fr)"
      >
        {DAYS.map((_, index) => {
          return (
            <Box
              /** Data is static, can key by index */
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={clsx(styles.day, {
                [styles.isSelected]: index === 10,
              })}
            >
              {index + 1}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
